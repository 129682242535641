import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { H1, P } from "../components/EasyText"
import Link from "../components/Link"
import { Box, Paper, Container } from "@material-ui/core"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container maxWidth="md">
      <Box p={4} component="main" clone>
        <Paper variant="outlined">
          <H1 gutterBottom>404: Page not found</H1>
          <Box align="center">
            <P paragraph>It looks like this page doesn&apos;t exist.</P>
            <P paragraph>
              <Link linkIsButton variant="outlined" color="secondary" to="">Home page</Link>
            </P>
          </Box>
        </Paper>
      </Box>
    </Container>
  </Layout>
)

export default NotFoundPage
